import { cn } from '@/Lib'
import { PageProps } from '@/types'
import { usePage } from '@inertiajs/react'
import { motion, MotionProps, useSpring, useTransform } from 'framer-motion'
import { FC, HTMLAttributes, useEffect, useMemo } from 'react'
import { ZAR } from './Intl'

type MoneyProps = {
  value: number
  maximumFractionDigits?: number
  // Make use of the hide_amounts value to hide input value
  canHide?: boolean
} & HTMLAttributes<HTMLSpanElement> &
  MotionProps

export const Money: FC<MoneyProps> = ({
  value,
  maximumFractionDigits = 2,
  canHide = true,
  className,
  ...props
}) => {
  const { hide_amounts } = usePage<PageProps>().props
  const spring = useSpring(value, { duration: 300, bounce: 0 })
  const zar = useTransform(spring, (current) =>
    ZAR(current, maximumFractionDigits)
  )

  useEffect(() => {
    spring.set(value)
  }, [spring, value])

  const hiddenZar = useMemo(() => {
    const zar = ZAR(value, maximumFractionDigits)
    return (
      zar.substring(0, 2) +
      Array(zar.substring(2).length).fill('\u2022').join('')
    )
  }, [maximumFractionDigits, value])

  return (
    <motion.span className={cn('tabular-nums', className)} {...props}>
      {canHide && hide_amounts ? hiddenZar : zar}
    </motion.span>
  )
}
